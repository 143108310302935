const colors = {
  primaryDark: '#3E3E3E',
  primaryLight: '#FFFF',
  secondary1: '#848484',
  secondary2: '#C4C4C4',
  secondary3: '#A0A0A0',
  secondary4: '#F8F8F8',
  secondary5: '#E0E0E0',
  secondary6: '#646464',
  secondary7: '#C9C9C9',
  secondary8: '#EBEBEB',
  inactive: '#E3E3E3',
  black: '#000000',
};
export default colors;
