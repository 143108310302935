import React, { useEffect, useState } from 'react';
import {
  BooleanField,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import { CreateButton, EditButton, ShowButton } from '@pankod/refine-mui';
import { IAccount } from '../../types';
import RolesModal from '../../Components/RolesModal';
import _ from 'lodash';
import { RiAdminLine } from 'react-icons/ri';
import { BsTrash } from 'react-icons/bs';
import { TbTrashOff } from 'react-icons/tb';
import { EditRecordAction } from '../../Actions/ConfigsActions';
import Swal from 'sweetalert2';

export const AdminListing: React.FC = () => {
  const [modal, setModal] = useState<IAccount>();
  const [mergedAdmins, setMergedAdmins] = React.useState<IAccount[]>([]);

  const { tableQueryResult } = useTable({
    syncWithLocation: true,
    initialPageSize: 1000,
  });

  const admins = useTable<IAccount>({
    resource: 'account-cruds',
    initialPageSize: 100000000000000,
  });

  const handleRefresh = () => {
    tableQueryResult.refetch();
    admins.tableQueryResult.refetch();
  };

  const handleDeleteRecord = (id: string) => {
    Swal.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#db2828',
      confirmButtonText: 'Accept',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        EditRecordAction(
          'account-cruds',
          id,
          { is_deleted: true },
          handleRefresh
        );
      }
    });
  };

  const handleRestoreRecord = (id: string) => {
    Swal.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#db2828',
      confirmButtonText: 'Accept',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        EditRecordAction(
          'account-cruds',
          id,
          { is_deleted: false },
          handleRefresh
        );
      }
    });
  };

  useEffect(() => {
    if (tableQueryResult.data?.data && admins?.tableQueryResult?.data?.data) {
      const merged: React.SetStateAction<any[]> = [];
      admins?.tableQueryResult?.data?.data?.filter((item) => {
        return tableQueryResult?.data?.data?.filter((admin) => {
          if (item.id === admin.account_id) {
            return merged.push({
              ...item,
              ...admin,
            });
          } else {
            return null;
          }
        });
      });
      const reOrderByDate = _.sortBy(merged, (item) => {
        return item.created_at;
      }).reverse();
      return setMergedAdmins(reOrderByDate);
    }
  }, [admins?.tableQueryResult?.data?.data, tableQueryResult.data?.data]);

  return (
    <List
      title={`${'Admin'}`}
      pageHeaderProps={{
        extra: <CreateButton style={{ marginRight: 10 }}>Add new</CreateButton>,
      }}
    >
      <Table dataSource={mergedAdmins} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={'ID'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={['name']}
          title={'Name'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="role"
          title={'Role'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={['phone_number']}
          title={'Phone number'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={['email']}
          title={'Email'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={['country']}
          title={'Country'}
          align="center"
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={['is_deleted']}
          title={'Deleted'}
          align="center"
          render={(value) => <BooleanField value={value} />}
        />

        <Table.Column<any>
          title={'Actions'}
          dataIndex="actions"
          fixed="right"
          align="center"
          key="actions"
          render={(_text, record): any => {
            return (
              <Space>
                <ShowButton
                  hideText
                  size="small"
                  recordItemId={record.account_id}
                />
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.account_id}
                />
                <RiAdminLine
                  style={{ marginLeft: 3, marginTop: 3 }}
                  size={19}
                  color={'#1a76d2'}
                  className="icons"
                  onClick={() => setModal(record)}
                />
                {record.is_deleted ? (
                  <TbTrashOff
                    style={{ marginLeft: 3, marginTop: 5 }}
                    size={20}
                    color={'green'}
                    className="icons"
                    onClick={() => handleRestoreRecord(record.account_id)}
                  />
                ) : (
                  <BsTrash
                    style={{ marginLeft: 3, marginTop: 5 }}
                    size={18}
                    color={'red'}
                    className="icons"
                    onClick={() => handleDeleteRecord(record.account_id)}
                  />
                )}
              </Space>
            );
          }}
        />
      </Table>
      {modal && (
        <RolesModal
          visible={modal}
          setVisible={setModal}
          refresh={handleRefresh}
          isLoading={tableQueryResult.isLoading}
        />
      )}
    </List>
  );
};
