export type AxiosHeaders = {
  defaults: {
    headers: {
      api_key?: string;
      Authorization?: string;
      device_id?: string;
      BUNDLE_ID?: string;
    };
  };
};

export type IAccount = {
  id: number;
  first_name: string;
  last_name: string;
  country: string;
  phone: number;
  name: string;
  email: string;
  is_deleted: boolean;
  phone_number: string;
  role: string;
  account_type: string;
  password: string;
  tokens: {
    access_token: string;
    refresh_token: string;
  };
  provider: {
    account_id: number;
    created_at: Date;
    current_type: string;
    id: number;
    is_available: Boolean;
    lat: null;
    lng: null;
    provider_type: string;
    updated_at: Date;
  };
  providerDocuments: {
    account_id: number;
    created_at: Date;
    id: number;
    is_deleted: boolean;
    provider_document_type: string;
    status: string;
    updated_at: Date;
    upload_id: number;
  }[];
  address: string;
  badges_count: number;
  birthdate: string;
  completed_journeys_count: number;
  completed_tracks_count: number;
  created_at: string;
  gender: string;
  is_subscribed: boolean;
  is_new_user: boolean;
  location: string;
  meditation_experience: string;
  object_type: string;
  plays_count: number;
  profile_picture_url: string;
  seconds_listened: number;
  status: string;
  updated_at: Date;
  points: number;
  rate_count: number;
  average_rate: number;
  is_email_confirmed: boolean;
  is_phone_confirmed: boolean;
  dob: Date;
};

export type Admin = {
  account: IAccount;
  account_id: number;
  role: string;
  id: number;
  created_at: Date;
  updated_at: Date;
  is_deleted: Boolean;
};

export type SystemConfig = {
  id?: number;
  CloneID?: string;
  CountryID?: string;
  CardTypeID?: string;
  StationID?: string;
  PaymentFailureEmails?: string;
  Logo?: string;
  created_at?: Date;
  updated_at?: Date;
  Active?: boolean;
  BundleID?: string;
  LanguageID?: string;
  CountryISOCode?: string;
  ReferralEquivelant?: number;
  ReferralCurrency?: string;
  ReferralActive?: boolean;
  OwnerID?: string;
  TabletId?: string;
  build_config?: {
    app_name: string | null;
    slug_name: string | null;
    expo_project_id?: string | null;
    app_identifier: string | null;
    firebase_api_key: string | null;
    firebase_auth_domain: string | null;
    firebase_project_id: string | null;
    firebase_message_sender_id: string | null;
    firebase_app_id: string | null;
    country_iso_code: string | null;
    tid11: string | null;
    payment_provider: string | null;
    production_payment_merchant_id: string | null;
    app_logo: string | null;
    branch_name?: string;
    app_icon: string | null;
    app_description: string | null;
    expo_client_id: string | null;
    web_client_id: string | null;
    android_client_id: string | null;
    ios_client_id: string | null;
    terms_and_conditions_url: string | null;
    privacy_and_policy_url: string | null;
    google_services_file: string | null;
    google_services_info_plist?: string | null;
    primaryDark?: string | null;
    primaryLight?: string | null;
    secondary1?: string | null;
    secondary2?: string | null;
    inactive?: string | null;
    black?: string | null;
  };
  apn_configs?: {};
  // firebase_json: {
  //   type?: string | null;
  //   project_id: string | null;
  //   private_key_id?: string | null;
  //   private_key: string | null;
  //   client_email: string | null;
  //   client_id?: string | null;
  //   auth_uri?: string | null;
  //   token_uri?: string | null;
  //   auth_provider_x509_cert_url?: string | null;
  //   client_x509_cert_url?: string | null;
  // };
};

export enum OsEnum {
  Android = 'android',
  Browser = 'browser',
  Ios = 'ios',
}
export enum LanguageEnum {
  Ar = 'ar',
  En = 'en',
}

export const AccountType = ['user', 'admin', 'provider'];

export type Colors = {
  primaryDark?: string;
  primaryLight?: string;
  secondary1?: string;
  secondary2?: string;
  inactive?: string;
  black?: string;
};
