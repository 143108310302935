import { Input, Select, Typography } from '@pankod/refine-antd';
import React from 'react';
import { CustomUploadDagger } from '../../Components/CustomUploadDagger';
import { GET_UPLOAD_URI } from '../../configs';
import { ImageOptions } from './CreateSystemConfigs';
interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: any;
  errors: any;
  logoOption: string;
  setLogoOption: (val: string) => void;
  type: string;
  title: string;
}
const ImageComponent = ({
  setFieldValue,
  handleChange,
  values,
  errors,
  logoOption,
  setLogoOption,
  type,
  title,
}: Props) => {
  return (
    <>
      <ImageOptions>
        <Select
          placeholder={'Logo Options'}
          onChange={(val) => setLogoOption(val)}
          value={logoOption}
          options={[
            { label: 'Upload Image', value: 'upload' },
            { label: 'Add Logo Url', value: 'input' },
          ]}
        />
      </ImageOptions>
      {logoOption === 'upload' && (
        <CustomUploadDagger
          name={'file'}
          onChange={(id: string) =>
            setFieldValue(type, `${GET_UPLOAD_URI}/${id}`)
          }
          label={title}
          value={values?.[type]}
          formItemName={type}
          errorMessage={errors?.[type]}
        />
      )}
      {logoOption === 'input' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Input
            name={type}
            placeholder="Add Logo Url"
            onChange={handleChange}
          />
          {errors?.app_logo && (
            <Typography.Text style={{ color: 'red', marginTop: -23 }}>
              {errors?.app_logo}
            </Typography.Text>
          )}
        </div>
      )}
    </>
  );
};

export default ImageComponent;
