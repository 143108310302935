import React from 'react';
import {
  BooleanField,
  Grid,
  Show,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import { DetailsWrapper, ColumnWrapper } from '../../App';
import { Admin, IAccount } from '../../types';
import dayjs from 'dayjs';
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
export const ShowAdmin = () => {
  const screens = useBreakpoint();

  const { tableProps, tableQueryResult } = useTable<IAccount>({
    resource: `account-cruds`,
    initialPageSize: 100000000000000,
  });
  const admin = useTable<Admin>({
    resource: 'admin-cruds',
    initialPageSize: 100000000000000,
  });
  const record = tableProps?.dataSource?.find(
    (item) => item?.id === +window.location.href.split('show/')[1]
  );

  const adminRecord = admin?.tableProps?.dataSource?.find(
    (item) => item?.account_id === +window.location.href.split('show/')[1]
  );

  return (
    <>
      <Show isLoading={tableQueryResult.isLoading}>
        <DetailsWrapper screenMD={!!screens.md}>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Name</Title>
            <Text>{record?.name || '-'}</Text>

            <Title level={5}>Email</Title>
            <Text>{record?.email || '-'}</Text>
          </ColumnWrapper>
          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}>Phone Number</Title>
            <Text>{record?.phone_number || '-'}</Text>

            <Title level={5}>Role</Title>
            <Text>{adminRecord?.role || '-'}</Text>
          </ColumnWrapper>

          <ColumnWrapper screenMD={!!screens.md}>
            <Title level={5}> Is Deleted</Title>
            <BooleanField value={record?.is_deleted} />

            <Title level={5}>Created At</Title>
            <Text>
              {record?.created_at
                ? dayjs(record?.created_at).format('MMMM DD, YYYY HH:MM')
                : '-'}
            </Text>
          </ColumnWrapper>
        </DetailsWrapper>
      </Show>
    </>
  );
};
