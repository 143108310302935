import React, { ChangeEvent, ChangeEventHandler } from 'react';
import { Form, Input, Select, Switch, Typography } from '@pankod/refine-antd';

type Props = {
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | any;
  value?: string | number;
  switchValue?: boolean;
  select?: boolean;
  selectOptions?: { label: string; value: string }[];
  placeholder?: string;
  type?: string;
  label?: string;
  required?: boolean;
  rules?: any;
  selectValue?: ChangeEvent<HTMLInputElement> | null;
  errorMessage?: string;
  formItemName?: string;
  toggleSwitch?: boolean;
  textArea?: boolean;
  disabled?: boolean;
  accept?: string;
  id?: string;
  style?: any;
  hidden?: boolean;
  hiddenText?: string;
  labelStyle?: any;
};
export const CustomInput = ({
  name,
  onChange,
  value,
  placeholder,
  select,
  type,
  label,
  required,
  rules,
  toggleSwitch,
  selectValue,
  selectOptions,
  errorMessage,
  textArea,
  formItemName,
  switchValue,
  disabled,
  accept,
  id,
  hidden,
  hiddenText,
  style,
  labelStyle,
}: Props) => {
  return (
    <Form.Item
      label={label}
      name={formItemName}
      required={required}
      rules={rules}
      style={labelStyle}
    >
      {select ? (
        <Select
          options={selectOptions}
          defaultValue={selectValue}
          placeholder={'Select Country'}
          onChange={onChange}
          disabled={disabled}
          id={id}
        />
      ) : toggleSwitch ? (
        <Switch
          onChange={onChange}
          defaultChecked={!!switchValue}
          disabled={disabled}
          id={id}
        />
      ) : textArea ? (
        <Input.TextArea
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          style={style}
          id={id}
        />
      ) : (
        <label htmlFor={id}>
          {hiddenText && <p style={style}>{hiddenText}</p>}
          <Input
            type={type}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            disabled={disabled}
            accept={'file_extension'}
            id={id}
            hidden={hidden}
            style={style}
          />
        </label>
      )}
      {errorMessage && (
        <Typography.Text style={{ color: 'red' }}>
          {errorMessage}
        </Typography.Text>
      )}
    </Form.Item>
  );
};
