import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { Avatar, Form, Space, Typography, Upload } from '@pankod/refine-antd';
import noPhoto from './noPhoto.png';
import { API_KEY, BUNDLE_ID, POST_UPLOAD_URI } from '../../configs';
import { AccountStore, ConfigsStore } from '../../Store';

type Props = {
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement> | any;
  value: string | number;
  placeholder?: string;
  type?: string;
  label?: string;
  required?: boolean;
  rules?: any;
  errorMessage?: string;
  disabled?: boolean;
  formItemName: string;
  getValueFromEvent?: any;
  data?: any;
};
export const CustomUploadDagger = ({
  name,
  onChange,
  value,
  label,
  required,
  rules,
  errorMessage,
  disabled,
  formItemName,
  data,
  getValueFromEvent,
}: Props) => {
  const [headers, setHeaders] = useState<{ token: string; device_id: string }>({
    device_id: '',
    token: '',
  });
  const getHeaders = async () => {
    const device_id = await ConfigsStore.get('device_id');
    const account = await AccountStore.get('account');
    setHeaders({
      token: account?.tokens?.access_token || '',
      device_id: device_id || '',
    });
  };

  useEffect(() => {
    getHeaders();
  }, []);

  return (
    <Form.Item
      label={label || ''}
      name={formItemName}
      required={required}
      rules={rules}
      getValueFromEvent={(value) => getValueFromEvent(value)}
    >
      <Upload.Dragger
        name={name}
        action={POST_UPLOAD_URI}
        listType="picture"
        maxCount={1}
        data={data}
        disabled={disabled}
        showUploadList={false}
        onChange={(file) => onChange(file?.file?.response?.id)}
        headers={{
          Authorization: `Bearer ${headers?.token}`,
          device_id: headers?.device_id,
          api_key: API_KEY,
          BUNDLE_ID,
        }}
      >
        <Space direction="vertical" size={2}>
          <Avatar
            style={{
              width: '50%',
              height: '50%',
              maxWidth: '150px',
              borderRadius: 5,
            }}
            src={value ? value : noPhoto}
            alt="Banner"
          />
        </Space>
      </Upload.Dragger>

      {errorMessage && (
        <Typography.Text style={{ color: 'red' }}>
          {errorMessage}
        </Typography.Text>
      )}
    </Form.Item>
  );
};
