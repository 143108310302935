import React, { useState } from 'react';
// import logo from '.././../ images/logo.png';
import './style.css';
import {
  AntdLayout,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from '@pankod/refine-antd';
import { useLogin } from '@pankod/refine-core';
import { ConfigsStore } from '../../../Store/ConfigsStore';
import Colors from '../../../Theme/Colors';

const { Title } = Typography;
export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}
export const Login = () => {
  const [form] = Form.useForm<ILoginForm>();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const { mutate: login } = useLogin<ILoginForm>();
  const CardTitle = (
    <Title
      level={3}
      className="layout-title"
      style={{ color: Colors.primaryDark, marginBottom: '-15px' }}
    >
      {'Sign in to your account'}
    </Title>
  );
  return (
    <AntdLayout
      style={{
        background: `radial-gradient(50% 50% at 50% 50%,${Colors.secondary4} 0%,rgba(0, 0, 0, 0.5) 100%)`,
        backgroundSize: 'cover',
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* <img
              style={{ marginBottom: 26, alignSelf: 'center' }}
              src={logo}
              width="180"
              alt="logo"
            /> */}
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                color={Colors.primaryDark}
                requiredMark={false}
                onFinish={(values) => {
                  login(values);
                }}
              >
                <Form.Item
                  name="username"
                  label={'Email or Phone number'}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="sample@sample.com" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={'Password'}
                  rules={[{ required: true }]}
                  style={{ marginBottom: '12px' }}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <div style={{ marginBottom: '25px' }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{
                        fontSize: '12px',
                      }}
                      onClick={() => setRememberMe(!rememberMe)}
                    >
                      {'Remember me'}
                    </Checkbox>
                  </Form.Item>
                  <a
                    style={{
                      float: 'right',
                      fontSize: '12px',
                      textDecoration: 'underline',
                      color: Colors.primaryDark,
                    }}
                    href="/forgot-password"
                  >
                    {'Forgot password?'}
                  </a>
                </div>
                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={Colors.primaryDark}
                  style={{
                    background: Colors.secondary1,
                  }}
                  onClick={() => {
                    if (rememberMe) {
                      ConfigsStore.set('rememberMe', true);
                    } else {
                      sessionStorage.setItem('logoutOnEndSession', 'true');
                    }
                  }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: 17,
                      borderColor: Colors.primaryDark,
                    }}
                  >
                    {'Sign in'}
                  </p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
