import * as React from 'react';
import { Create, Form, Input, Select } from '@pankod/refine-antd';
import countries from '../../JsonAssests/countries.json';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useNavigation } from '@pankod/refine-core';
import { useFormik } from 'formik';
import { CreateRecordAction } from '../../Actions/ConfigsActions';
import { errorMessage } from '../../Utils/ErrorMessage';

export const CreateAdmin = () => {
  const { list } = useNavigation();

  const navigateToEdit = (id: string) => {
    list('admin-cruds');
  };

  const { handleSubmit, setFieldValue, handleChange } = useFormik({
    initialValues: {
      password: '',
      role: '',
      name: '',
      phone_number: '',
      email: '',
      country: '',
    },

    onSubmit: async (submittedValues) => {
      if (
        submittedValues.password &&
        submittedValues.role &&
        submittedValues.name &&
        submittedValues.phone_number &&
        submittedValues.email &&
        submittedValues.country
      ) {
        CreateRecordAction('admin/create', submittedValues, navigateToEdit);
      }
    },
  });

  const selectCountries = (countries || [])?.map((country) => {
    return { label: country?.name_en, value: country.name_en };
  });

  const buttonProps = {
    disables: false,
    loading: false,
    onClick: () => handleSubmit(),
  };

  return (
    <Create saveButtonProps={buttonProps} title={'New Admin'}>
      <Form layout="vertical">
        <Form.Item label="Name" name="name" required rules={errorMessage()}>
          <Input
            name="name"
            autoComplete="off"
            placeholder="Name"
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          required
          rules={[
            {
              validator: async (_, email) => {
                const regex =
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (!regex.test(email)) {
                  return Promise.reject(new Error('Invalid Email'));
                }
              },
            },
          ]}
        >
          <Input
            name="email"
            autoComplete="off"
            placeholder="Email"
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone_number"
          required
          rules={[
            {
              validator: async (_, phone) => {
                const phoneNumber = parsePhoneNumber(phone, 'JO');
                if (!phoneNumber.isValid()) {
                  return Promise.reject(new Error('Invalid phone number'));
                }
              },
            },
          ]}
        >
          <Input
            name="phone_number"
            autoComplete="off"
            placeholder="Phone Number"
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          required
          rules={errorMessage()}
        >
          <Select
            options={selectCountries}
            placeholder={'Select Country'}
            onChange={(val) => setFieldValue('country', val)}
          />
        </Form.Item>
        <Form.Item label="Role" name="role" required rules={errorMessage()}>
          <Select
            placeholder="Select Role"
            onChange={(val) => setFieldValue('role', val)}
            options={[
              { label: 'super_admin', value: 'super_admin' },
              { label: 'admin', value: 'admin' },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          required
          rules={[
            {
              validator: async (_, password) => {
                if (password.length < 8) {
                  return Promise.reject(
                    new Error('Password must be at least 8 characters')
                  );
                }
              },
            },
          ]}
        >
          <Input
            type="password"
            name="password"
            autoComplete="off"
            placeholder="●●●●●●●●"
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
