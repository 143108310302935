import * as React from 'react';
import {
  Col,
  Create,
  Form,
  Row,
  Typography,
  Button,
} from '@pankod/refine-antd';
import { useNavigation } from '@pankod/refine-core';
import { useFormik } from 'formik';
import { CreateRecordAction } from '../../Actions/ConfigsActions';
import { CustomInput } from '../../Components/CustomInput';
import * as Yup from 'yup';
import { SystemConfig } from '../../types';
import styled from 'styled-components';
import { ColorResult, SketchPicker } from 'react-color';
import Colors from '../../Theme/Colors';
import ImageComponent from './ImageComponent';
import colors from '../../Theme/Colors';
export interface CurrentColorOption {
  key: keyof typeof Colors;
  value: string;
}

export const CreateSystemConfigs = () => {
  const [logoOption, setLogoOption] = React.useState('upload');
  const [appIconOption, setAppIcon] = React.useState('upload');

  const [currentColorSet, setCurrentColorSet] =
    React.useState<CurrentColorOption | null>();

  const { list } = useNavigation();

  const navigateToList = () => {
    list('system-configs-cruds');
  };

  const handleOpenColorPicker = ({ key, value }: CurrentColorOption) => {
    setCurrentColorSet({
      key,
      value,
    });
  };

  const handleColorPickerChange = (currentColor: ColorResult) => {
    currentColorSet &&
      setCurrentColorSet({
        ...currentColorSet,
        value: currentColor.hex,
      });
  };

  const handleCloseColorPicker = () => {
    currentColorSet && setCurrentColorSet(null);
  };

  const handleConfirmColorPicker = () => {
    currentColorSet?.key &&
      setFieldValue(currentColorSet?.key, currentColorSet?.value);
    currentColorSet && setCurrentColorSet(null);
  };

  const validationSchema = Yup.object().shape({
    BundleID: Yup.string()
      .matches(
        /^com\.\w+\.\w+$/,
        'Bundle ID must be in the format com.example.example'
      )
      .required('Bundle ID is required'),
    OwnerID: Yup.string().required('Owner ID is required'),
    CardTypeID: Yup.string().required('Card Type ID is required'),
    CloneID: Yup.string().required('Clone ID is required'),
    StationID: Yup.string().required('Station ID is required'),
    expo_project_id: Yup.string().required('Expo Project ID is required'),
    PaymentFailureEmails: Yup.string().required(
      'Payment Failure Emails is required'
    ),
    LanguageID: Yup.string().required('Language ID is required'),
    CountryID: Yup.string().required('Country ID is required'),
    CountryISOCode: Yup.string().required('Country ISO Code is required'),
    firebase_api_key: Yup.string().required('Firebase Api Key is required'),
    firebase_auth_domain: Yup.string().required(
      'Firebase Auth Domain is required'
    ),
    firebase_app_id: Yup.string().required('Firebase App ID is required'),
    firebase_message_sender_id: Yup.string().required(
      'Firebase Message Sender ID is required'
    ),
    firebase_project_id: Yup.string().required(
      'Firebase Project ID is required'
    ),
    app_name: Yup.string().required('App Name is required'),
    ReferralCurrency: Yup.string().required('Referral Currency is required'),
    ReferralEquivelant: Yup.number().required(
      'Referral Equivalent is required'
    ),
    android_client_id: Yup.string().required('Android Client ID is required'),
    ios_client_id: Yup.string().required('IOS Client ID is required'),
    web_client_id: Yup.string().required('Web Client ID is required'),
    // expo_client_id: Yup.string().required('Expo Client ID is required'),
    payment_provider: Yup.string().required('Payment Provider is required'),
    app_description: Yup.string().required('App Description is required'),
    production_payment_merchant_id: Yup.string().required(
      'Production Payment Merchant ID is required'
    ),
    slug_name: Yup.string().required('Slug Name is required'),
    tid11: Yup.string().required('TID11 is required'),
    privacy_and_policy_url: Yup.string().required(
      'Privacy and Policy Url is required'
    ),
    terms_and_conditions_url: Yup.string().required(
      'Terms and Conditions Url is required'
    ),
    app_logo: Yup.string().required('App Logo is required'),
    app_icon: Yup.string().required('App Icon is required'),
    google_services_file: Yup.string().required(
      'Google Services File is required'
    ),
    google_services_info_plist: Yup.string().required(
      'Google Services Info Plist is required'
    ),
    primaryDark: Yup.string().required('primaryDark color is required'),
    primaryLight: Yup.string().required('primaryLight color is required'),
    secondary1: Yup.string().required('secondary1 color is required'),
    secondary2: Yup.string().required('secondary2 color is required'),
    inactive: Yup.string().required('inactive color is required'),
  });

  const { values, handleSubmit, setFieldValue, handleChange, errors } =
    useFormik({
      initialValues: {
        BundleID: '',
        OwnerID: '',
        CardTypeID: '',
        expo_project_id: '',
        CloneID: '',
        StationID: '',
        // TabletId: '',
        PaymentFailureEmails: '',
        LanguageID: '',
        CountryID: '',
        CountryISOCode: '',
        country_iso_code: '',
        firebase_api_key: '',
        firebase_app_id: '',
        firebase_auth_domain: '',
        firebase_message_sender_id: '',
        firebase_project_id: '',
        app_name: '',
        ReferralCurrency: '',
        ReferralEquivelant: 0,
        android_client_id: '',
        expo_client_id: '',
        app_identifier: '',
        app_description: '',
        ios_client_id: '',
        web_client_id: '',
        production_payment_merchant_id: '',
        slug_name: '',
        tid11: '',
        privacy_and_policy_url: '',
        terms_and_conditions_url: '',
        app_logo: '',
        app_icon: '',
        Logo: '',
        google_services_file: '',
        google_services_info_plist: '',
        Active: false,
        ReferralActive: false,
        primaryDark: '',
        primaryLight: '',
        secondary1: '',
        secondary2: '',
        inactive: '',
        type: '',
        project_id: '',
        private_key_id: '',
        private_key: '',
        client_email: '',
        client_id: '',
        auth_uri: '',
        token_uri: '',
        auth_provider_x509_cert_url: '',
        client_x509_cert_url: '',
        payment_provider: '',
      },
      validationSchema,
      onSubmit: async (submittedValues) => {
        const inputs: SystemConfig = {
          BundleID: submittedValues.BundleID,
          OwnerID: submittedValues.OwnerID,
          CardTypeID: submittedValues.CardTypeID,
          CloneID: submittedValues.CloneID,
          StationID: submittedValues.StationID,
          // TabletId: submittedValues.TabletId,
          PaymentFailureEmails: submittedValues.PaymentFailureEmails,
          LanguageID: submittedValues.LanguageID,
          CountryID: submittedValues.CountryID,
          CountryISOCode: submittedValues.CountryISOCode,
          ReferralCurrency: submittedValues.ReferralCurrency,
          ReferralEquivelant: submittedValues.ReferralEquivelant,
          // Logo: submittedValues.app_logo,
          Active: submittedValues.Active,
          ReferralActive: submittedValues.ReferralActive,
          build_config: {
            android_client_id: submittedValues.android_client_id,
            expo_project_id: submittedValues.expo_project_id,
            payment_provider: submittedValues.payment_provider,
            expo_client_id: submittedValues.expo_client_id,
            app_description: encodeURIComponent(
              submittedValues.app_description
            ),
            ios_client_id: submittedValues.ios_client_id,
            web_client_id: submittedValues.web_client_id,
            production_payment_merchant_id:
              submittedValues.production_payment_merchant_id,
            slug_name: submittedValues.slug_name,
            tid11: submittedValues.tid11,
            privacy_and_policy_url: submittedValues.privacy_and_policy_url,
            terms_and_conditions_url: submittedValues.terms_and_conditions_url,
            app_logo: submittedValues.app_logo,
            app_icon: submittedValues.app_icon,
            country_iso_code: submittedValues.CountryISOCode,
            firebase_api_key: submittedValues.firebase_api_key,
            firebase_app_id: submittedValues.firebase_app_id,
            firebase_auth_domain: submittedValues.firebase_auth_domain,
            firebase_message_sender_id:
              submittedValues.firebase_message_sender_id,
            firebase_project_id: submittedValues.firebase_project_id,
            app_name: submittedValues.app_name,
            app_identifier: submittedValues.BundleID,
            google_services_file: JSON.stringify(
              JSON.parse(submittedValues.google_services_file)
            ),
            google_services_info_plist:
              submittedValues.google_services_info_plist,
            primaryDark: submittedValues.primaryDark,
            primaryLight: submittedValues.primaryLight,
            secondary1: submittedValues.secondary1,
            secondary2: submittedValues.secondary2,
            inactive: submittedValues.inactive,
          },
        };

        if (Object.keys(inputs)?.length > 0) {
          CreateRecordAction(
            'system-configs-cruds/create-new-app',
            inputs,
            navigateToList
          );
        }
      },
    });

  const handleUploadFile = (e: { target: { files: Blob[] } }, type: string) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      setFieldValue(type, e?.target?.result);
    };
  };

  const buttonProps = {
    disables: false,
    loading: false,
    onClick: () => handleSubmit(),
  };

  return (
    <Create saveButtonProps={buttonProps} title={'New System Config'}>
      <Form layout="vertical">
        <Typography.Title level={3}>App Configs</Typography.Title>
        <Row gutter={[64, 0]} wrap align={'middle'}>
          <Col xs={24} lg={8} style={{ alignSelf: 'flex-start' }}>
            <CustomInput
              name={'BundleID'}
              required
              onChange={handleChange}
              value={values?.BundleID}
              label={'Bundle ID'}
              errorMessage={errors?.BundleID}
            />
            <CustomInput
              name={'expo_project_id'}
              required
              onChange={handleChange}
              value={values?.expo_project_id}
              label={'Expo Project ID'}
              errorMessage={errors?.expo_project_id}
            />
            <CustomInput
              label={'Owner ID'}
              name={'OwnerID'}
              required
              onChange={handleChange}
              value={values?.OwnerID}
              errorMessage={errors?.OwnerID}
            />
            <CustomInput
              label={'Card Type ID'}
              name={'CardTypeID'}
              required
              onChange={handleChange}
              value={values?.CardTypeID}
              errorMessage={errors?.CardTypeID}
            />
            <CustomInput
              label={'Clone ID'}
              name={'CloneID'}
              required
              onChange={handleChange}
              value={values?.CloneID}
              errorMessage={errors?.CloneID}
            />

            <Divider />

            <Typography.Title level={3}>App Info</Typography.Title>

            <CustomInput
              label={'App Name'}
              name={'app_name'}
              required
              onChange={handleChange}
              value={values?.app_name}
              errorMessage={errors?.app_name}
              style={{ marginBottom: 22 }}
            />

            <Divider />

            <Typography.Title level={3}>Firebase Configs</Typography.Title>

            <CustomInput
              label={'Firebase Project ID'}
              name={'firebase_project_id'}
              required
              onChange={handleChange}
              value={values?.firebase_project_id}
              errorMessage={errors?.firebase_project_id}
            />

            <CustomInput
              label={'Firebase App ID'}
              name={'firebase_app_id'}
              required
              onChange={handleChange}
              value={values?.firebase_app_id}
              errorMessage={errors?.firebase_app_id}
            />

            <CustomInput
              label={'Firebase Api Key'}
              name={'firebase_api_key'}
              required
              onChange={handleChange}
              value={values?.firebase_api_key}
              errorMessage={errors?.firebase_api_key}
            />

            <Divider />
            <Typography.Title level={3}>App Configs</Typography.Title>
            <CustomInput
              label={'Payment Provider'}
              name={'payment_provider'}
              required
              onChange={handleChange}
              value={values?.payment_provider}
              errorMessage={errors?.payment_provider}
            />

            <CustomInput
              label={'Payment Failure Emails'}
              name={'PaymentFailureEmails'}
              required
              onChange={handleChange}
              value={values?.PaymentFailureEmails}
              errorMessage={errors?.PaymentFailureEmails}
            />

            <CustomInput
              label={'Country ISO Code'}
              name="CountryISOCode"
              select
              placeholder={'Select Country'}
              required
              selectOptions={[
                { label: 'Jordan', value: 'JO' },
                { label: 'Egypt', value: 'EG' },
              ]}
              onChange={(val: string) => setFieldValue('CountryISOCode', val)}
              selectValue={
                values?.CountryISOCode as unknown as React.ChangeEvent<HTMLInputElement>
              }
              errorMessage={errors?.CountryISOCode}
            />

            <Divider />
          </Col>

          <Col xs={24} lg={8} style={{ alignSelf: 'flex-start' }}>
            <CustomInput
              label={'Station ID'}
              name={'StationID'}
              required
              onChange={handleChange}
              value={values?.StationID}
              errorMessage={errors?.StationID}
            />

            <CustomInput
              label={'Language ID'}
              name={'LanguageID'}
              required
              onChange={handleChange}
              value={values?.LanguageID}
              errorMessage={errors?.LanguageID}
            />
            <CustomInput
              label={'Country ID'}
              name={'CountryID'}
              required
              onChange={handleChange}
              value={values?.CountryID}
              errorMessage={errors?.CountryID}
            />

            <CustomInput
              label={'Production Payment Merchant ID'}
              name={'production_payment_merchant_id'}
              required
              onChange={handleChange}
              value={values?.production_payment_merchant_id}
              errorMessage={errors?.production_payment_merchant_id}
            />

            <CustomInput label={' '} style={{ display: 'none' }} />

            <Divider />

            <Typography.Title level={3}> &nbsp;</Typography.Title>
            <CustomInput
              label={'Slug Name '}
              name={'slug_name'}
              required
              onChange={handleChange}
              value={values?.slug_name}
              errorMessage={errors?.slug_name}
              style={{ marginBottom: 22 }}
            />
            <Divider />
            <Typography.Title level={3}> &nbsp;</Typography.Title>
            <CustomInput
              label={'Firebase Message Sender ID'}
              name={'firebase_message_sender_id'}
              required
              onChange={handleChange}
              value={values?.firebase_message_sender_id}
              errorMessage={errors?.firebase_message_sender_id}
            />

            <CustomInput
              label={'Firebase Auth Domain'}
              name={'firebase_auth_domain'}
              required
              onChange={handleChange}
              value={values?.firebase_auth_domain}
              errorMessage={errors?.firebase_auth_domain}
            />
            <CustomInput label={' '} style={{ display: 'none' }} />

            <Divider />
            <Typography.Title level={3}> &nbsp;</Typography.Title>

            <CustomInput
              label={'Tid11'}
              name={'tid11'}
              required
              onChange={handleChange}
              value={values?.tid11}
              errorMessage={errors?.tid11}
            />

            <CustomInput
              label={'Referral Equivalent'}
              type="number"
              name={'ReferralEquivelant'}
              required
              onChange={handleChange}
              value={values?.ReferralEquivelant}
              errorMessage={errors?.ReferralEquivelant}
            />

            <CustomInput
              label={'Referral Currency'}
              name={'ReferralCurrency'}
              required
              onChange={handleChange}
              value={values?.ReferralCurrency}
              errorMessage={errors?.ReferralCurrency}
            />

            <Divider />
          </Col>

          <Col xs={24} lg={8} style={{ alignSelf: 'flex-start' }}>
            <CustomInput
              label={'Android Client ID'}
              name={'android_client_id'}
              required
              onChange={handleChange}
              value={values?.android_client_id}
              errorMessage={errors?.android_client_id}
            />
            <CustomInput
              label={'Expo Client ID'}
              name={'expo_client_id'}
              required
              onChange={handleChange}
              value={values?.expo_client_id}
              errorMessage={errors?.expo_client_id}
            />

            <CustomInput
              label={'Ios Client ID'}
              name={'ios_client_id'}
              required
              onChange={handleChange}
              value={values?.ios_client_id}
              errorMessage={errors?.ios_client_id}
            />
            <CustomInput
              label={'Web Client ID'}
              name={'web_client_id'}
              required
              onChange={handleChange}
              value={values?.web_client_id}
              errorMessage={errors?.web_client_id}
            />
            <CustomInput label={' '} style={{ display: 'none' }} />

            <Divider />
            <Typography.Title level={3}> &nbsp;</Typography.Title>

            <CustomInput
              label={'App Description'}
              name={'app_description'}
              textArea
              required
              onChange={handleChange}
              value={values?.app_description}
              errorMessage={errors?.app_description}
            />

            <Divider />

            <Typography.Title level={3}> &nbsp;</Typography.Title>

            <CustomInput
              label={'Google Services File'}
              name={'google_services_file'}
              type="file"
              accept="application/JSON"
              onChange={(e: { target: { files: Blob[] } }) =>
                handleUploadFile(e, 'google_services_file')
              }
            />
            <CustomInput
              label={'Google Services Info Plist File'}
              name={'google_services_info_plist'}
              type="file"
              onChange={(e: { target: { files: Blob[] } }) =>
                handleUploadFile(e, 'google_services_info_plist')
              }
            />
            <CustomInput label={' '} style={{ display: 'none' }} />

            <Divider style={{ marginTop: -12 }} />
            <Typography.Title level={3}> &nbsp;</Typography.Title>

            <CustomInput
              label={'Privacy And Policy Url'}
              name={'privacy_and_policy_url'}
              required
              onChange={handleChange}
              value={values?.privacy_and_policy_url}
              errorMessage={errors?.privacy_and_policy_url}
            />

            <CustomInput
              label={'Terms And Conditions Url'}
              name={'terms_and_conditions_url'}
              required
              onChange={handleChange}
              value={values?.terms_and_conditions_url}
              errorMessage={errors?.terms_and_conditions_url}
            />
            <CustomInput label={' '} style={{ display: 'none' }} />

            <Divider />
          </Col>
        </Row>
        <ImageComponent
          errors={errors.app_logo}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          logoOption={logoOption}
          values={values}
          setLogoOption={setLogoOption}
          type="app_logo"
          title="App Logo"
        />
        <ImageComponent
          errors={errors.app_icon}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          logoOption={appIconOption}
          values={values}
          setLogoOption={setAppIcon}
          type="app_icon"
          title="App Icon"
        />
        <Row wrap gutter={[64, 0]}>
          <Col xs={24} lg={8} style={{ alignSelf: 'flex-start' }}>
            <div>
              <Typography.Text
                style={{ color: '#626262', fontWeight: 700, fontSize: 15 }}
              >
                {'Colors:'}
              </Typography.Text>
              {!!currentColorSet && (
                <div
                  style={{
                    position: 'fixed',
                    top: '35%',
                    right: '50%',
                    zIndex: 9999,
                  }}
                >
                  <SketchPicker
                    onChangeComplete={handleColorPickerChange}
                    color={currentColorSet.value}
                    styles={{
                      default: {
                        picker: {},
                      },
                    }}
                  />
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                      onClick={handleConfirmColorPicker}
                      type="default"
                      style={{ width: '100%' }}
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={handleCloseColorPicker}
                      type="default"
                      style={{ width: '100%' }}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 5,
                width: 250,
              }}
            >
              <Typography.Text
                style={{ color: '#626262', fontWeight: 500, fontSize: 15 }}
              >
                {'primaryDark:'}
              </Typography.Text>
              <Button
                type="default"
                style={{ marginLeft: 10, backgroundColor: values?.primaryDark }}
                onClick={(e) =>
                  handleOpenColorPicker({
                    key: 'primaryDark',
                    value: values?.primaryDark,
                  })
                }
              >
                Choose Color
              </Button>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 5,
                  width: 250,
                }}
                onClick={() =>
                  handleOpenColorPicker({
                    key: 'primaryLight',
                    value: values?.primaryDark,
                  })
                }
              >
                <Typography.Text
                  style={{ color: '#626262', fontWeight: 500, fontSize: 15 }}
                >
                  {'primaryLight:'}
                </Typography.Text>
                <Button
                  type="default"
                  style={{
                    marginLeft: 10,
                    backgroundColor: values?.primaryLight,
                  }}
                  onClick={(e) =>
                    handleOpenColorPicker({
                      key: 'primaryLight',
                      value: values?.primaryLight,
                    })
                  }
                >
                  Choose Color
                </Button>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 5,
                  width: 250,
                }}
                onClick={() =>
                  handleOpenColorPicker({
                    key: 'secondary1',
                    value: values?.secondary1,
                  })
                }
              >
                <Typography.Text
                  style={{ color: '#626262', fontWeight: 500, fontSize: 15 }}
                >
                  {'secondary1:'}
                </Typography.Text>
                <Button
                  type="default"
                  style={{
                    marginLeft: 10,
                    backgroundColor: values?.secondary1,
                  }}
                  onClick={(e) =>
                    handleOpenColorPicker({
                      key: 'secondary1',
                      value: values?.secondary1,
                    })
                  }
                >
                  Choose Color
                </Button>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 5,
                  width: 250,
                }}
                onClick={() =>
                  handleOpenColorPicker({
                    key: 'secondary2',
                    value: values?.secondary2,
                  })
                }
              >
                <Typography.Text
                  style={{ color: '#626262', fontWeight: 500, fontSize: 15 }}
                >
                  {'secondary2:'}
                </Typography.Text>
                <Button
                  type="default"
                  style={{
                    marginLeft: 10,
                    backgroundColor: values?.secondary2,
                  }}
                  onClick={(e) =>
                    handleOpenColorPicker({
                      key: 'secondary2',
                      value: values?.secondary2,
                    })
                  }
                >
                  Choose Color
                </Button>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 5,
                  width: 250,
                }}
                onClick={() =>
                  handleOpenColorPicker({
                    key: 'inactive',
                    value: values?.inactive,
                  })
                }
              >
                <Typography.Text
                  style={{ color: '#626262', fontWeight: 500, fontSize: 15 }}
                >
                  {'inactive:'}
                </Typography.Text>
                <Button
                  type="default"
                  style={{ marginLeft: 10, backgroundColor: values?.inactive }}
                  onClick={(e) =>
                    handleOpenColorPicker({
                      key: 'inactive',
                      value: values?.inactive,
                    })
                  }
                >
                  Choose Color
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={8} style={{ alignSelf: 'flex-start' }}>
            <CustomInput
              labelStyle={{ marginTop: 5 }}
              label={'Active'}
              name={'Active'}
              toggleSwitch
              onChange={(e: boolean) => setFieldValue('Active', e)}
              switchValue={values?.Active}
            />
            <CustomInput
              label={'Referral Active'}
              name={'ReferralActive'}
              toggleSwitch
              onChange={(e: boolean) => setFieldValue('ReferralActive', e)}
              switchValue={values?.ReferralActive}
            />
          </Col>
        </Row>
      </Form>
    </Create>
  );
};

export const ImageOptions = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const Divider = styled.div`
  height: 2px;
  background-color: ${colors.secondary2};
  margin-bottom: 20px;
`;
