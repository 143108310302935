import { notification } from '@pankod/refine-antd';
import { AccountStore, ConfigsStore } from '../Store';
import { apiRequest } from '../Utils/APIs';
import { LanguageEnum, OsEnum } from '../types';
import { BUNDLE_ID } from '../configs';

export const CreateDeviceAction = async () => {
  const currentDevice = await ConfigsStore.get('device_id');
  if (currentDevice) return;
  try {
    const { data: deviceData } = await apiRequest<{ id: string }>({
      url: 'admin/create-device',
      method: 'POST',
      data: {
        os: OsEnum.Browser,
        language: LanguageEnum.En,
      },
    });
    if (!deviceData) throw new Error('Something went wrong!');
    await ConfigsStore.set('device_id', deviceData?.id);
  } catch (error: any) {
    throw new Error('Error');
  }
};
export const EditRecordAction = async (
  type: string,
  id: any,
  data: any,
  refetch?: () => void,
  withRefresh?: boolean,
  resetForm?: () => void,
  withoutAlert?: boolean
) => {
  try {
    await apiRequest<boolean>({
      url: `/${type}/${id}`,
      method: 'PATCH',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens.access_token
        }`,
        BUNDLE_ID,
      },
    });
    resetForm && resetForm();
    refetch && refetch();
    !withoutAlert &&
      notification.success({
        message: 'Success',
        description: 'Record updated successfully',
      });
    withRefresh && window.location.reload();
  } catch (error: any) {
    notification.error({ message: 'Error', description: error?.message });
  }
};
export const CreateRecordAction = async (
  url: string,
  data: any,
  navigateToEdit?: (url: string, id?: string) => void,
  goBack?: () => void,
  refetch?: () => void
) => {
  try {
    const { data: res } = await apiRequest<{ id: any }>({
      url,
      method: 'POST',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens.access_token
        }`,
        BUNDLE_ID,
      },
    });
    if (res) {
      goBack && goBack();
      navigateToEdit && navigateToEdit(url, res?.id);
      refetch && refetch();
      notification.success({
        message: 'Success',
        description: 'Record created successfully',
      });
    }
  } catch (error) {
    notification.error({
      message: 'Error',
      description: 'Error in creating record',
    });
  }
};

export const GetOneRecordAction = async (type: string, id: string) => {
  try {
    await apiRequest<boolean>({
      url: `/${type}/${id}`,
      method: 'GET',
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens.access_token
        }`,
        BUNDLE_ID,
      },
    });
  } catch (error: any) {
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const MergeBranchAction = async (id: number) => {
  try {
    await apiRequest<boolean>({
      url: `/${'system-configs-cruds/update-system-configs-branch'}`,
      method: 'POST',
      data: { id },
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens?.access_token
        }`,
        BUNDLE_ID,
      },
    });
    notification.success({
      message: 'Success',
      description: 'Branch merged successfully',
    });
  } catch (error: any) {
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const EditAppAction = async (
  type: string,
  data: any,
  refetch?: () => void,
  withRefresh?: boolean,
  resetForm?: () => void,
  withoutAlert?: boolean
) => {
  try {
    await apiRequest<boolean>({
      url: `/${type}`,
      method: 'POST',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens.access_token
        }`,
        BUNDLE_ID,
      },
    });
    resetForm && resetForm();
    refetch && refetch();
    !withoutAlert &&
      notification.success({
        message: 'Success',
        description: 'Record updated successfully',
      });
    withRefresh && window.location.reload();
  } catch (error: any) {
    notification.error({ message: 'Error', description: error?.message });
  }
};

export const RefetchMerchantsAction = async () => {
  try {
    await apiRequest<boolean>({
      url: '/merchant-cruds/refetchMerchants',
      method: 'PUT',
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.tokens?.access_token
        }`,
        BUNDLE_ID,
      },
    });
    notification.success({
      message: 'Success',
      description: 'Refetched Merchants successfully',
    });
  } catch (error: any) {
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};
