export const errorMessage = (message?: string, errorCondition?: boolean) => {
  const rules = [
    {
      validator: async (_: any, val: any) => {
        if (errorCondition) {
          return Promise.reject(new Error(message ? message : 'مطلوب'));
        }
        if (!val) {
          return Promise.reject(new Error(message ? message : 'مطلوب'));
        }
      },
    },
  ];
  return rules;
};

export function isEncoded(str: string) {
  return str.indexOf('%') !== -1;
}
