import React, { useState } from 'react';
// import logo from '.././../../ images/logo.png';

import {
  AntdLayout,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from '@pankod/refine-antd';

import { ForgotPasswordAction } from '../../../Actions/AuthActions';
import { useNavigation } from '@pankod/refine-core';
import Colors from '../../../Theme/Colors';

const { Title } = Typography;
export const ForgotPassword: React.FC = () => {
  const [Disabled, setDisabled] = useState(false);
  const { push } = useNavigation();
  const navigateToVerification = (email: string) => {
    push('/verification');
    return email;
  };
  const CardTitle = (
    <Title level={3} className="layout-title">
      {'Forgot Password '}
    </Title>
  );
  const handleDisableButton = (value: boolean) => {
    setDisabled(value);
  };
  return (
    <AntdLayout
      style={{
        background: `radial-gradient(50% 50% at 50% 50%,${Colors.secondary4} 0%,rgba(0, 0, 0, 0.5) 100%)`,
        backgroundSize: 'cover',
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* <Image src={logo} width="180" alt="logo" /> */}
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                color={Colors.primaryLight}
                onFinish={(values) => {
                  ForgotPasswordAction(
                    values,
                    navigateToVerification,
                    handleDisableButton
                  );
                }}
                requiredMark={false}
              >
                <Form.Item
                  name="email"
                  label={' Email'}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="sample@smaple.com" />
                </Form.Item>
                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={Colors.primaryDark}
                  style={{
                    background: Colors.secondary1,
                  }}
                  disabled={Disabled}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: 17,
                      borderColor: Colors.primaryDark,
                    }}
                  >
                    {'Send'}
                  </p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
