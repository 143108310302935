export const domain = (() => {
  return window.location.host === 'localhost:3000'
    ? 'https://loynova-loyalty.dev.wtmsrv.com'
    : window.location.host.includes('staging')
    ? `https://loynova-loyalty.staging.wtmsrv.com`
    : `https://api.loyalty.loynova.com`;
})();

export const API_URL = `${domain}/api`;
export const POST_UPLOAD_URI = `${API_URL}/upload/file`;
export const GET_UPLOAD_URI = `${API_URL}/upload`;
export const BUNDLE_ID = 'dev.wtm.loynova.loyalty';
export const API_KEY = 'TE9ZTk9WQUxPWUFMVFlBUElLRVlHVUFSRDIwMjI';
