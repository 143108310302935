import { useEffect, useState } from 'react';
import { Refine } from '@pankod/refine-core';
import {
  Layout,
  ReadyPage,
  notificationProvider,
  ErrorComponent,
} from '@pankod/refine-antd';
import routerProvider from '@pankod/refine-react-router-v6';
import dataProvider from './Providers/DataProvider';
import { ConfigsStore } from './Store';
import { CreateDeviceAction } from './Actions/ConfigsActions';
import authProvider from './Providers/AuthProvider';
import dayjs from 'dayjs';

import '@pankod/refine-antd/dist/styles.min.css';
import { Login } from './Containers/AuthPages/Login/Login';
import { API_URL } from './configs';
import { axiosInstance, LogoutAction } from './Actions/AuthActions';
import { ForgotPassword } from './Containers/AuthPages/ ForgotPassword/ index';
import { Verification } from './Containers/AuthPages/Verification';
import { ResetPassword } from './Containers/AuthPages/ResetPassword/ index';
import styled from 'styled-components';
import { RiAdminLine } from 'react-icons/ri';
import { FiSettings } from 'react-icons/fi';
import { CreateAdmin } from './Containers/admin/CreateAdmin';
import { EditAdmin } from './Containers/admin/EditAdmin';
import { ShowAdmin } from './Containers/admin/ShowAdmin';
import { AdminListing } from './Containers/admin/AdminListing';
import { SystemConfigsListing } from './Containers/SystemConfigs/SystemConfigsListing';
import { ShowSystemConfigs } from './Containers/SystemConfigs/ShowSystemConfigs';
import { CreateSystemConfigs } from './Containers/SystemConfigs/CreateSystemConfigs';
import { EditSystemConfigs } from './Containers/SystemConfigs/EditSystemConfig';
import logo from './Images/logo.png';
import * as Sentry from '@sentry/react';

const { RouterComponent } = routerProvider;
const CustomRouterComponent = () => <RouterComponent basename="/" />;

const App: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>();
  const [checkCookie, setCheckCookie] = useState<any>();
  const [checkSessionStorage, setSessionStorage] = useState<any>();

  const routing = {
    ...routerProvider,
    RouterComponent: CustomRouterComponent,
    routes: [
      {
        exact: true,
        element: loggedIn ? <ErrorComponent /> : <ForgotPassword />,
        path: '/forgot-password',
      },
      {
        exact: true,
        element: loggedIn ? <ErrorComponent /> : <Verification />,
        path: '/verification',
      },
      {
        exact: true,
        element: loggedIn ? <ErrorComponent /> : <ResetPassword />,
        path: '/reset-password',
      },
    ],
  };
  const getCookie = async () => {
    setLoggedIn(await ConfigsStore.get('logged_in'));
    setCheckCookie(await ConfigsStore.get('cookieExpiration'));
    setSessionStorage(await sessionStorage.getItem('logoutOnEndSession'));
  };

  useEffect(() => {
    if (checkCookie?.value) {
      if (dayjs(checkCookie?.expiry).format() <= dayjs(new Date()).format()) {
        LogoutAction(true);
      }
    } else {
      if (checkSessionStorage !== 'true' && loggedIn) {
        LogoutAction(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCookie, checkCookie?.value, checkSessionStorage]);

  useEffect(() => {
    getCookie();
    CreateDeviceAction();
  }, []);

  return (
    <Refine
      Title={({ collapsed }) => (
        <AppLogo>
          {!collapsed && (
            <img
              style={{ marginBottom: 20, alignSelf: 'center' }}
              src={logo}
              width="150px"
              alt="logo"
            />
          )}
          {collapsed && (
            <img
              style={{ marginBottom: 20, alignSelf: 'center' }}
              src={logo}
              width="70px"
              alt="logo"
            />
          )}
        </AppLogo>
      )}
      routerProvider={routing}
      // @ts-ignore
      dataProvider={dataProvider(API_URL, axiosInstance)}
      Layout={Layout}
      ReadyPage={ReadyPage}
      LoginPage={Login}
      authProvider={authProvider}
      notificationProvider={notificationProvider}
      catchAll={<ErrorComponent />}
      resources={[
        {
          name: 'admin-cruds',
          options: { label: 'Admins' },
          list: AdminListing,
          show: ShowAdmin,
          edit: EditAdmin,
          create: CreateAdmin,
          icon: <RiAdminLine style={{ marginRight: 10 }} size={20} />,
        },
        {
          name: 'system-configs-cruds',
          options: { label: 'System Configs' },
          list: SystemConfigsListing,
          show: ShowSystemConfigs,
          edit: EditSystemConfigs,
          create: CreateSystemConfigs,
          icon: <FiSettings style={{ marginRight: 10 }} size={20} />,
        },
      ]}
    />
  );
};

export default Sentry.withProfiler(App);

export const DetailsWrapper = styled.div<{ screenMD: boolean }>`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.screenMD ? '' : 'space-around')};
`;
export const ColumnWrapper = styled.div<{ screenMD: boolean }>`
  margin: ${(props) => (props.screenMD ? '' : '20px')};
`;

export const Separator = styled.div`
  margin-top: 15px;
`;

export const DescriptionWrapper = styled.div<{ screenMD?: boolean }>`
  width: ${(props) => (props.screenMD ? '250px' : '150px')};
  margin-bottom: 15px;
`;

export const AppLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
`;
