import axios, { Method } from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { API_KEY, API_URL } from '../configs';
import * as Sentry from '@sentry/react';

const api = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    api_key: API_KEY,
  },
});

api.interceptors.response.use(
  (response) => {
    Sentry.addBreadcrumb({
      category: 'API Response',
      message: 'Response Successful',
      level: 'info',
      data: {
        ...response,
      },
    });
    return response;
  },
  (error) => {
    Sentry.addBreadcrumb({
      category: 'API Response',
      message: 'Response Failed',
      level: 'error',
      data: {
        ...error,
      },
    });

    Sentry.captureException(error, {
      level: 'error',
      fingerprint: ['{{ default }}', '{{ transaction }}'],
      contexts: {
        error: {
          message: error.message,
          name: error.name,
          stack: error.stack,
        },
      },
    });

    return Promise.reject(error);
  }
);
api.interceptors.request.use(
  (config) => {
    Sentry.addBreadcrumb({
      category: 'API Request',
      message: 'Request',
      level: 'info',
      data: {
        ...config,
      },
    });
    return config;
  },
  (error) => {
    Sentry.addBreadcrumb({
      category: 'API Request',
      message: 'Request Failed',
      level: 'error',
      data: {
        ...error,
      },
    });

    Sentry.captureException(error, {
      level: 'error',
      fingerprint: ['{{ default }}', '{{ transaction }}'],
      contexts: {
        error: {
          message: error.message,
          name: error.name,
          stack: error.stack,
        },
      },
    });
    return Promise.reject(error);
  }
);

const useAxios = makeUseAxios({ axios: api });

type Api_Request = {
  url: string;
  method?: Method;
  data?: {};
  params?: {};
  headers?: {};
  manualTrigger?: boolean;
};

/**
 * useApi Hook
 * @example
 * ```
 * const {} = useApi({});
 * ```
 **/

export const useApi = <DATA>(req: Api_Request) => {
  const [{ data, loading, error, response: debug }, run] = useAxios<DATA>(
    {
      url: req.url,
      method: req.method || 'GET',
      data: req.data || {},
      params: req.params || {},
      headers: req.headers || {},
    },
    {
      manual: req.manualTrigger || false,
    }
  );

  return { data, loading, error, run, debug };
};

/**
 * Simple asynchronous api request method
 * @example without token
 * ```
 * const data = await apiRequest({});
 * ```
 **/
export const apiRequest = async <DATA>(req: Api_Request) => {
  return api.request<DATA>({
    url: req.url,
    method: req.method || 'GET',
    data: req.data || {},
    params: req.params || {},
    headers: req.headers || {},
  });
};
