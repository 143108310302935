import * as React from 'react';
import {
  Button,
  Col,
  Create,
  Form,
  Row,
  Typography,
} from '@pankod/refine-antd';
import { useForm } from '@pankod/refine-core';
import { useFormik } from 'formik';
import { EditAppAction } from '../../Actions/ConfigsActions';
import { CustomInput } from '../../Components/CustomInput';
import { SystemConfig } from '../../types';
import * as Yup from 'yup';
import ImageComponent from './ImageComponent';
import { ColorResult, SketchPicker } from 'react-color';
import { CurrentColorOption, Divider } from './CreateSystemConfigs';
import { isEncoded } from '../../Utils/ErrorMessage';

type Inputs = {
  updateSystemConfigsInputs: SystemConfig;
  editNote: string;
};

export const EditSystemConfigs = () => {
  const [logoOption, setLogoOption] = React.useState('upload');
  const [appIconOption, setAppIcon] = React.useState('upload');
  const [currentColorSet, setCurrentColorSet] =
    React.useState<CurrentColorOption | null>();

  const handleOpenColorPicker = ({ key, value }: CurrentColorOption) => {
    setCurrentColorSet({
      key,
      value,
    });
  };

  const handleColorPickerChange = (currentColor: ColorResult) => {
    currentColorSet &&
      setCurrentColorSet({
        ...currentColorSet,
        value: currentColor.hex,
      });
  };

  const handleCloseColorPicker = () => {
    currentColorSet && setCurrentColorSet(null);
  };

  const handleConfirmColorPicker = () => {
    currentColorSet?.key &&
      setFieldValue(currentColorSet?.key, currentColorSet?.value);
    currentColorSet && setCurrentColorSet(null);
  };

  // @ts-ignore
  const { queryResult, saveButtonProps } = useForm<SystemConfig>({});
  const record = queryResult?.data?.data;

  const validationSchema = Yup.object().shape({
    editNote: Yup.string().required('Required'),
  });

  const { values, handleSubmit, setFieldValue, handleChange, errors } =
    useFormik({
      initialValues: {
        id: record?.id,
        BundleID: '',
        OwnerID: '',
        CardTypeID: '',
        CloneID: '',
        StationID: '',
        // TabletId: '',
        PaymentFailureEmails: '',
        LanguageID: '',
        CountryID: '',
        CountryISOCode: '',
        country_iso_code: '',
        firebase_api_key: '',
        firebase_app_id: '',
        firebase_auth_domain: '',
        firebase_message_sender_id: '',
        firebase_project_id: '',
        app_name: '',
        ReferralCurrency: '',
        ReferralEquivelant: 0,
        android_client_id: '',
        expo_client_id: '',
        app_identifier: '',
        app_description: '',
        ios_client_id: '',
        web_client_id: '',
        production_payment_merchant_id: '',
        slug_name: '',
        tid11: '',
        privacy_and_policy_url: '',
        terms_and_conditions_url: '',
        app_logo: '',
        app_icon: '',
        Logo: '',
        branch_name: '',
        google_services_file: '',
        google_services_info_plist: '',
        Active: false,
        ReferralActive: false,
        editNote: '',
        primaryDark: '',
        primaryLight: '',
        secondary1: '',
        secondary2: '',
        inactive: '',
        type: '',
        project_id: '',
        private_key_id: '',
        private_key: '',
        client_email: '',
        client_id: '',
        auth_uri: '',
        token_uri: '',
        auth_provider_x509_cert_url: '',
        client_x509_cert_url: '',
        expo_project_id: '',
        payment_provider: '',
      },
      validationSchema,
      onSubmit: async (submittedValues) => {
        const inputs: Inputs = {
          editNote: submittedValues.editNote || '',
          updateSystemConfigsInputs: {
            id: record?.id,
            build_config: {
              app_identifier: submittedValues.BundleID || null,
              country_iso_code: submittedValues.CountryISOCode || null,
              firebase_api_key: submittedValues.firebase_api_key || null,
              firebase_app_id: submittedValues.firebase_app_id || null,
              expo_project_id: submittedValues.expo_project_id || null,
              firebase_auth_domain:
                submittedValues.firebase_auth_domain || null,
              firebase_message_sender_id:
                submittedValues.firebase_message_sender_id || null,
              firebase_project_id: submittedValues.firebase_project_id || null,
              payment_provider: submittedValues.payment_provider || null,
              app_name: submittedValues.app_name || null,
              android_client_id: submittedValues.android_client_id || null,
              expo_client_id: submittedValues.expo_client_id || null,
              app_description: submittedValues.app_description
                ? encodeURIComponent(submittedValues.app_description)
                : null,
              ios_client_id: submittedValues.ios_client_id || null,
              web_client_id: submittedValues.web_client_id || null,
              production_payment_merchant_id:
                submittedValues.production_payment_merchant_id || null,
              slug_name: submittedValues.slug_name || null,
              tid11: submittedValues.tid11 || null,
              privacy_and_policy_url:
                submittedValues.privacy_and_policy_url || null,
              terms_and_conditions_url:
                submittedValues.terms_and_conditions_url || null,
              app_logo: submittedValues.app_logo || null,
              app_icon: submittedValues.app_icon || null,
              google_services_file: submittedValues.google_services_file
                ? JSON.stringify(
                    JSON.parse(submittedValues.google_services_file)
                  )
                : null,
              google_services_info_plist:
                submittedValues.google_services_info_plist
                  ? submittedValues.google_services_info_plist
                  : null,
              primaryDark: submittedValues.primaryDark || null,
              primaryLight: submittedValues.primaryLight || null,
              secondary1: submittedValues.secondary1 || null,
              secondary2: submittedValues.secondary2 || null,
              inactive: submittedValues.inactive || null,
            },
          },
        };
        if (submittedValues.BundleID !== record?.BundleID) {
          inputs.updateSystemConfigsInputs.BundleID = submittedValues.BundleID;
        }
        if (submittedValues.OwnerID !== record?.OwnerID) {
          inputs.updateSystemConfigsInputs.OwnerID = submittedValues.OwnerID;
        }
        if (submittedValues.CardTypeID !== record?.CardTypeID) {
          inputs.updateSystemConfigsInputs.CardTypeID =
            submittedValues.CardTypeID;
        }
        if (submittedValues.CloneID !== record?.CloneID) {
          inputs.updateSystemConfigsInputs.CloneID = submittedValues.CloneID;
        }
        if (submittedValues.StationID !== record?.StationID) {
          inputs.updateSystemConfigsInputs.StationID =
            submittedValues.StationID;
        }
        // if (submittedValues.TabletId !== record?.TabletId) {
        //   inputs.updateSystemConfigsInputs.TabletId = submittedValues.TabletId;
        // }
        if (
          submittedValues.PaymentFailureEmails !== record?.PaymentFailureEmails
        ) {
          inputs.updateSystemConfigsInputs.PaymentFailureEmails =
            submittedValues.PaymentFailureEmails;
        }
        if (submittedValues.LanguageID !== record?.LanguageID) {
          inputs.updateSystemConfigsInputs.LanguageID =
            submittedValues.LanguageID;
        }
        if (submittedValues.CountryID !== record?.CountryID) {
          inputs.updateSystemConfigsInputs.CountryID =
            submittedValues.CountryID;
        }
        if (submittedValues.CountryISOCode !== record?.CountryISOCode) {
          inputs.updateSystemConfigsInputs.CountryISOCode =
            submittedValues.CountryISOCode;
        }

        if (submittedValues.ReferralCurrency !== record?.ReferralCurrency) {
          inputs.updateSystemConfigsInputs.ReferralCurrency =
            submittedValues.ReferralCurrency;
        }
        if (submittedValues.ReferralEquivelant !== record?.ReferralEquivelant) {
          inputs.updateSystemConfigsInputs.ReferralEquivelant =
            submittedValues.ReferralEquivelant;
        }

        if (submittedValues.Active !== record?.Active) {
          inputs.updateSystemConfigsInputs.Active = submittedValues.Active;
        }
        if (submittedValues.ReferralActive !== record?.ReferralActive) {
          inputs.updateSystemConfigsInputs.ReferralActive =
            submittedValues.ReferralActive;
        }
        if (Object.keys(inputs)?.length > 0) {
          EditAppAction('system-configs-cruds/edit-app', inputs);
        }
      },
    });

  React.useEffect(() => {
    if (record) {
      setFieldValue('BundleID', record?.BundleID);
      setFieldValue('OwnerID', record?.OwnerID);
      setFieldValue('PaymentFailureEmails', record?.PaymentFailureEmails);
      setFieldValue('LanguageID', record?.LanguageID);
      setFieldValue('CardTypeID', record?.CardTypeID);
      setFieldValue('CloneID', record?.CloneID);
      setFieldValue('CountryID', record?.CountryID);
      setFieldValue('CountryISOCode', record?.CountryISOCode);
      setFieldValue('StationID', record?.StationID);
      setFieldValue('app_name', record?.build_config?.app_name);
      setFieldValue('payment_provider', record?.build_config?.payment_provider);
      setFieldValue('app_logo', record?.build_config?.app_logo);
      setFieldValue('slug_name', record?.build_config?.slug_name);
      setFieldValue('firebase_api_key', record?.build_config?.firebase_api_key);
      setFieldValue('firebase_app_id', record?.build_config?.firebase_app_id);
      setFieldValue('expo_project_id', record?.build_config?.expo_project_id);
      setFieldValue(
        'firebase_auth_domain',
        record?.build_config?.firebase_auth_domain
      );
      setFieldValue(
        'firebase_message_sender_id',
        record?.build_config?.firebase_message_sender_id
      );
      setFieldValue(
        'firebase_project_id',
        record?.build_config?.firebase_project_id
      );
      setFieldValue(
        'android_client_id',
        record?.build_config?.android_client_id
      );
      setFieldValue('expo_client_id', record?.build_config?.expo_client_id);
      setFieldValue('ios_client_id', record?.build_config?.ios_client_id);
      setFieldValue('web_client_id', record?.build_config?.web_client_id);
      setFieldValue(
        'production_payment_merchant_id',
        record?.build_config?.production_payment_merchant_id
      );
      setFieldValue('ReferralCurrency', record?.ReferralCurrency);
      setFieldValue('ReferralEquivelant', record?.ReferralEquivelant);
      setFieldValue(
        'app_description',
        record?.build_config?.app_description &&
          isEncoded(record?.build_config?.app_description)
          ? decodeURIComponent(record?.build_config?.app_description)
          : record?.build_config?.app_description
      );
      setFieldValue('tid11', record?.build_config?.tid11);
      setFieldValue('branch_name', record?.build_config?.branch_name);
      setFieldValue(
        'google_services_info_plist',
        record?.build_config?.google_services_info_plist
      );
      setFieldValue(
        'google_services_file',
        record?.build_config?.google_services_file
      );
      setFieldValue(
        'privacy_and_policy_url',
        record?.build_config?.privacy_and_policy_url
      );
      setFieldValue(
        'terms_and_conditions_url',
        record?.build_config?.terms_and_conditions_url
      );
      setFieldValue('Active', record?.Active);
      setFieldValue('ReferralActive', record?.ReferralActive);

      setFieldValue('app_icon', record?.build_config?.app_icon);

      setFieldValue('primaryDark', record?.build_config?.primaryDark);
      setFieldValue('primaryLight', record?.build_config?.primaryLight);
      setFieldValue('secondary1', record?.build_config?.secondary1);
      setFieldValue('secondary2', record?.build_config?.secondary2);
      setFieldValue('inactive', record?.build_config?.inactive);
    }
  }, [record, setFieldValue]);

  const buttonProps = {
    disables: saveButtonProps?.disables,
    loading: saveButtonProps?.loading,
    onClick: () => handleSubmit(),
  };

  if (!values.CountryISOCode) return null;

  const handleUploadFile = (e: { target: { files: Blob[] } }, type: string) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      setFieldValue(type, e?.target?.result);
    };
  };

  return (
    <Create saveButtonProps={buttonProps} title={'Edit System Config'}>
      <Form layout="vertical">
        <Typography.Title level={3}>App Configs</Typography.Title>
        <Row gutter={[64, 0]} wrap align={'middle'}>
          <Col xs={24} lg={8} style={{ alignSelf: 'flex-start' }}>
            {/* <CustomInput
              name={'Bundle ID'}
              required
              disabled
              onChange={handleChange}
              value={values?.BundleID}
              label={'Bundle ID'}
              errorMessage={errors?.BundleID}
            /> */}
            <CustomInput
              name={'expo_project_id'}
              required
              onChange={handleChange}
              value={values?.expo_project_id}
              label={'Expo Project ID'}
              errorMessage={errors?.expo_project_id}
            />
            <CustomInput
              label={'Owner ID'}
              name={'OwnerID'}
              required
              onChange={handleChange}
              value={values?.OwnerID}
              errorMessage={errors?.OwnerID}
            />
            <CustomInput
              label={'Card Type ID'}
              name={'CardTypeID'}
              required
              onChange={handleChange}
              value={values?.CardTypeID}
              errorMessage={errors?.CardTypeID}
            />
            <CustomInput
              label={'Clone ID'}
              name={'CloneID'}
              required
              onChange={handleChange}
              value={values?.CloneID}
              errorMessage={errors?.CloneID}
            />
            <Divider />
            <Typography.Title level={3}>App Info</Typography.Title>
            <CustomInput
              label={'App Name'}
              name={'app_name'}
              required
              onChange={handleChange}
              value={values?.app_name}
              errorMessage={errors?.app_name}
              style={{ marginBottom: 22 }}
            />

            <Divider />
            <Typography.Title level={3}>Firebase Configs</Typography.Title>
            <CustomInput
              label={'Firebase Project ID'}
              name={'firebase_project_id'}
              required
              onChange={handleChange}
              value={values?.firebase_project_id}
              errorMessage={errors?.firebase_project_id}
            />

            <CustomInput
              label={'Firebase App ID'}
              name={'firebase_app_id'}
              required
              onChange={handleChange}
              value={values?.firebase_app_id}
              errorMessage={errors?.firebase_app_id}
            />

            <CustomInput
              label={'Firebase Message Sender ID'}
              name={'firebase_message_sender_id'}
              required
              onChange={handleChange}
              value={values?.firebase_message_sender_id}
              errorMessage={errors?.firebase_message_sender_id}
            />

            <Divider />

            <Typography.Title level={3}>App Configs</Typography.Title>

            <CustomInput
              label={'Payment Provider'}
              name={'payment_provider'}
              required
              onChange={handleChange}
              value={values?.payment_provider}
              errorMessage={errors?.payment_provider}
            />

            <CustomInput
              label={'Payment Failure Emails'}
              name={'PaymentFailureEmails'}
              required
              onChange={handleChange}
              value={values?.PaymentFailureEmails}
              errorMessage={errors?.PaymentFailureEmails}
            />

            <CustomInput
              label={'Country ISO Code'}
              name="CountryISOCode"
              select
              placeholder={'Select Country'}
              required
              selectOptions={[
                { label: 'Jordan', value: 'JO' },
                { label: 'Egypt', value: 'EG' },
              ]}
              onChange={(val: string) => setFieldValue('CountryISOCode', val)}
              selectValue={
                values?.CountryISOCode as unknown as React.ChangeEvent<HTMLInputElement>
              }
              errorMessage={errors?.CountryISOCode}
            />

            <Divider />
          </Col>

          <Col xs={24} lg={8} style={{ alignSelf: 'flex-start' }}>
            <CustomInput
              label={'Station ID'}
              name={'StationID'}
              required
              onChange={handleChange}
              value={values?.StationID}
              errorMessage={errors?.StationID}
            />
            <CustomInput
              label={'Language ID'}
              name={'LanguageID'}
              required
              onChange={handleChange}
              value={values?.LanguageID}
              errorMessage={errors?.LanguageID}
            />
            <CustomInput
              label={'Country ID'}
              name={'CountryID'}
              required
              onChange={handleChange}
              value={values?.CountryID}
              errorMessage={errors?.CountryID}
            />

            <CustomInput
              label={'Production Payment Merchant ID'}
              name={'production_payment_merchant_id'}
              required
              onChange={handleChange}
              value={values?.production_payment_merchant_id}
              errorMessage={errors?.production_payment_merchant_id}
            />

            <Divider />

            <Typography.Title level={3}> &nbsp;</Typography.Title>

            <CustomInput
              label={'Slug Name '}
              name={'slug_name'}
              required
              onChange={handleChange}
              value={values?.slug_name}
              errorMessage={errors?.slug_name}
              style={{ marginBottom: 22 }}
            />

            <Divider />

            <Typography.Title level={3}> &nbsp;</Typography.Title>

            <CustomInput
              label={'Firebase Api Key'}
              name={'firebase_api_key'}
              required
              onChange={handleChange}
              value={values?.firebase_api_key}
              errorMessage={errors?.firebase_api_key}
            />

            <CustomInput
              label={'Firebase Auth Domain'}
              name={'firebase_auth_domain'}
              required
              onChange={handleChange}
              value={values?.firebase_auth_domain}
              errorMessage={errors?.firebase_auth_domain}
            />

            <CustomInput label={' '} style={{ display: 'none' }} />

            <Divider />
            <Typography.Title level={3}> &nbsp;</Typography.Title>
            <CustomInput
              label={'Tid11'}
              name={'tid11'}
              required
              onChange={handleChange}
              value={values?.tid11}
              errorMessage={errors?.tid11}
            />

            <CustomInput
              type="number"
              label={'Referral Equivalent'}
              name={'ReferralEquivelant'}
              required
              onChange={handleChange}
              value={values?.ReferralEquivelant}
              errorMessage={errors?.ReferralEquivelant}
            />
            <CustomInput
              label={'Referral Currency'}
              name={'ReferralCurrency'}
              required
              onChange={handleChange}
              value={values?.ReferralCurrency}
              errorMessage={errors?.ReferralCurrency}
            />

            <Divider />
          </Col>

          <Col xs={24} lg={8} style={{ alignSelf: 'flex-start' }}>
            <CustomInput
              label={'Android Client ID'}
              name={'android_client_id'}
              required
              onChange={handleChange}
              value={values?.android_client_id}
              errorMessage={errors?.android_client_id}
            />
            <CustomInput
              label={'Expo Client ID'}
              name={'expo_client_id'}
              required
              onChange={handleChange}
              value={values?.expo_client_id}
              errorMessage={errors?.expo_client_id}
            />

            <CustomInput
              label={'Ios Client ID'}
              name={'ios_client_id'}
              required
              onChange={handleChange}
              value={values?.ios_client_id}
              errorMessage={errors?.ios_client_id}
            />
            <CustomInput
              label={'Web Client ID'}
              name={'web_client_id'}
              required
              onChange={handleChange}
              value={values?.web_client_id}
              errorMessage={errors?.web_client_id}
            />

            <Divider />
            <Typography.Title level={3}> &nbsp;</Typography.Title>

            <CustomInput
              label={'App Description'}
              name={'app_description'}
              textArea
              required
              onChange={handleChange}
              value={values?.app_description}
              errorMessage={errors?.app_description}
            />

            <Divider />

            <Typography.Title level={3}> &nbsp;</Typography.Title>

            {values?.google_services_file ? (
              <CustomInput
                label={'Google Services File'}
                name={'google_services_file'}
                type="file"
                accept="application/JSON"
                onChange={(e: { target: { files: Blob[] } }) =>
                  handleUploadFile(e, 'google_services_file')
                }
                hidden
                hiddenText={'google-service.json'}
                id="google_services_file"
                style={{
                  cursor: 'pointer',
                  color: 'blue',
                  textDecoration: 'underline',
                }}
              />
            ) : (
              <CustomInput
                label={'Google Services File'}
                name={'google_services_file'}
                type="file"
                accept="application/JSON"
                onChange={(e: { target: { files: Blob[] } }) =>
                  handleUploadFile(e, 'google_services_file')
                }
              />
            )}
            {values?.google_services_info_plist ? (
              <CustomInput
                label={'Google Services Info Plist File'}
                name={'google_services_info_plist'}
                type="file"
                onChange={(e: { target: { files: Blob[] } }) =>
                  handleUploadFile(e, 'google_services_info_plist')
                }
                hidden
                hiddenText={'GoogleService-Info.plist'}
                accept="file_extension"
                id="google_services_info_plist"
                style={{
                  cursor: 'pointer',
                  color: 'blue',
                  textDecoration: 'underline',
                }}
              />
            ) : (
              <CustomInput
                label={'Google Services Info Plist File'}
                name={'google_services_info_plist'}
                type="file"
                accept="file_extension"
                onChange={(e: { target: { files: Blob[] } }) =>
                  handleUploadFile(e, 'google_services_info_plist')
                }
              />
            )}
            <CustomInput label={' '} style={{ display: 'none' }} />

            <Divider style={{ marginTop: -12 }} />
            <Typography.Title level={3}> &nbsp;</Typography.Title>

            <CustomInput
              label={'Privacy And Policy Url'}
              name={'privacy_and_policy_url'}
              required
              onChange={handleChange}
              value={values?.privacy_and_policy_url}
              errorMessage={errors?.privacy_and_policy_url}
            />

            <CustomInput
              label={'Terms And Conditions Url'}
              name={'terms_and_conditions_url'}
              required
              onChange={handleChange}
              value={values?.terms_and_conditions_url}
              errorMessage={errors?.terms_and_conditions_url}
            />
            <CustomInput label={' '} style={{ display: 'none' }} />

            <Divider />
          </Col>
        </Row>

        <ImageComponent
          errors={errors.app_logo}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          logoOption={logoOption}
          values={values}
          setLogoOption={setLogoOption}
          type="app_logo"
          title="App Logo"
        />
        <ImageComponent
          errors={errors.app_icon}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          logoOption={appIconOption}
          values={values}
          setLogoOption={setAppIcon}
          type="app_icon"
          title="App Icon"
        />
        <Row wrap gutter={[64, 0]}>
          <Col xs={24} lg={8} style={{ alignSelf: 'flex-start' }}>
            <div>
              <Typography.Text
                style={{ color: '#626262', fontWeight: 700, fontSize: 15 }}
              >
                {'Colors:'}
              </Typography.Text>
              {!!currentColorSet && (
                <div
                  style={{
                    position: 'fixed',
                    top: '35%',
                    right: '50%',
                    zIndex: 9999,
                  }}
                >
                  <SketchPicker
                    onChangeComplete={handleColorPickerChange}
                    color={currentColorSet.value}
                    styles={{
                      default: {
                        picker: {},
                      },
                    }}
                  />
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                      onClick={handleConfirmColorPicker}
                      type="default"
                      style={{ width: '100%' }}
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={handleCloseColorPicker}
                      type="default"
                      style={{ width: '100%' }}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 5,
                width: 250,
              }}
            >
              <Typography.Text
                style={{ color: '#626262', fontWeight: 500, fontSize: 15 }}
              >
                {'primaryDark:'}
              </Typography.Text>
              <Button
                type="default"
                style={{ marginLeft: 10, backgroundColor: values?.primaryDark }}
                onClick={(e) =>
                  handleOpenColorPicker({
                    key: 'primaryDark',
                    value: values?.primaryDark,
                  })
                }
              >
                Choose Color
              </Button>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 5,
                  width: 250,
                }}
                onClick={() =>
                  handleOpenColorPicker({
                    key: 'primaryLight',
                    value: values?.primaryDark,
                  })
                }
              >
                <Typography.Text
                  style={{ color: '#626262', fontWeight: 500, fontSize: 15 }}
                >
                  {'primaryLight:'}
                </Typography.Text>
                <Button
                  type="default"
                  style={{
                    marginLeft: 10,
                    backgroundColor: values?.primaryLight,
                  }}
                  onClick={(e) =>
                    handleOpenColorPicker({
                      key: 'primaryLight',
                      value: values?.primaryLight,
                    })
                  }
                >
                  Choose Color
                </Button>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 5,
                  width: 250,
                }}
                onClick={() =>
                  handleOpenColorPicker({
                    key: 'secondary1',
                    value: values?.secondary1,
                  })
                }
              >
                <Typography.Text
                  style={{ color: '#626262', fontWeight: 500, fontSize: 15 }}
                >
                  {'secondary1:'}
                </Typography.Text>
                <Button
                  type="default"
                  style={{
                    marginLeft: 10,
                    backgroundColor: values?.secondary1,
                  }}
                  onClick={(e) =>
                    handleOpenColorPicker({
                      key: 'secondary1',
                      value: values?.secondary1,
                    })
                  }
                >
                  Choose Color
                </Button>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 5,
                  width: 250,
                }}
                onClick={() =>
                  handleOpenColorPicker({
                    key: 'secondary2',
                    value: values?.secondary2,
                  })
                }
              >
                <Typography.Text
                  style={{ color: '#626262', fontWeight: 500, fontSize: 15 }}
                >
                  {'secondary2:'}
                </Typography.Text>
                <Button
                  type="default"
                  style={{
                    marginLeft: 10,
                    backgroundColor: values?.secondary2,
                  }}
                  onClick={(e) =>
                    handleOpenColorPicker({
                      key: 'secondary2',
                      value: values?.secondary2,
                    })
                  }
                >
                  Choose Color
                </Button>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 5,
                  width: 250,
                }}
                onClick={() =>
                  handleOpenColorPicker({
                    key: 'inactive',
                    value: values?.inactive,
                  })
                }
              >
                <Typography.Text
                  style={{ color: '#626262', fontWeight: 500, fontSize: 15 }}
                >
                  {'inactive:'}
                </Typography.Text>
                <Button
                  type="default"
                  style={{ marginLeft: 10, backgroundColor: values?.inactive }}
                  onClick={(e) =>
                    handleOpenColorPicker({
                      key: 'inactive',
                      value: values?.inactive,
                    })
                  }
                >
                  Choose Color
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={8} style={{ alignSelf: 'flex-start' }}>
            <CustomInput
              label={'Active'}
              name={'Active'}
              labelStyle={{ marginTop: 5 }}
              toggleSwitch
              onChange={(e: boolean) => setFieldValue('Active', e)}
              switchValue={values?.Active}
            />
            <CustomInput
              label={'Referral Active'}
              name={'ReferralActive'}
              toggleSwitch
              onChange={(e: boolean) => setFieldValue('ReferralActive', e)}
              switchValue={values?.ReferralActive}
            />
          </Col>
        </Row>

        <CustomInput
          label={'Edit Note'}
          name={'editNote'}
          textArea
          required
          onChange={handleChange}
          value={values?.editNote}
          errorMessage={errors?.editNote}
          labelStyle={{ marginTop: '20px' }}
        />
      </Form>
    </Create>
  );
};
