import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Form, Modal, Select } from '@pankod/refine-antd';
import { EditRecordAction } from '../../Actions/ConfigsActions';
interface Props {
  visible: any;
  setVisible: (val: any) => void;
  isLoading?: boolean;
  record?: any;
  listingData?: any;
  type?: string;
  refresh?: () => void;
}
type Inputs = {
  id?: number;
  role?: string;
};
const RolesModal = ({ visible, setVisible, refresh }: Props) => {
  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      id: visible.id,
      role: visible.role,
    },

    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        role: submittedValues.role,
      };
      if (Object.keys(inputs)?.length > 0) {
        EditRecordAction(
          'admin/update-admin',
          visible.account_id,
          inputs,
          refresh
        );
        setVisible(false);
      }
    },
  });

  useEffect(() => {
    setFieldValue('role', visible.role);
  }, [setFieldValue, visible]);

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => handleSubmit()}
      width={700}
    >
      <div style={{ marginTop: '20px' }}>
        <Form layout="vertical">
          <Form.Item label="Role">
            <Select
              options={[
                { label: 'Super Admin', value: 'super_admin' },
                { label: 'Admin', value: 'admin' },
              ]}
              defaultValue={values?.role}
              onChange={(val) => setFieldValue('role', val)}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default RolesModal;
