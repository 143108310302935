import React from 'react';
import {
  BooleanField,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import {
  Button,
  CreateButton,
  EditButton,
  ShowButton,
} from '@pankod/refine-mui';
import { BsTrash } from 'react-icons/bs';
import { TbTrashOff } from 'react-icons/tb';
import {
  EditRecordAction,
  RefetchMerchantsAction,
} from '../../Actions/ConfigsActions';
import Swal from 'sweetalert2';
import { SystemConfig } from '../../types';

export const SystemConfigsListing: React.FC = () => {
  const { tableProps, tableQueryResult } = useTable<SystemConfig[]>({
    syncWithLocation: true,
  });

  const handleRefresh = () => {
    tableQueryResult.refetch();
  };

  const handleDeleteRecord = (id: string) => {
    Swal.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#db2828',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        EditRecordAction(
          'account-cruds',
          id,
          { is_deleted: true },
          handleRefresh
        );
      }
    });
  };

  const handleRestoreRecord = (id: string) => {
    Swal.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#db2828',
      confirmButtonText: 'Restore',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        EditRecordAction(
          'account-cruds',
          id,
          { is_deleted: false },
          handleRefresh
        );
      }
    });
  };

  const handleRefetchMerchants = () => {
    Swal.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#23b53b',
      confirmButtonText: 'Accept',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        RefetchMerchantsAction();
      }
    });
  };

  return (
    <List
      title={`${'System Configs'}`}
      pageHeaderProps={{
        extra: (
          <>
            <CreateButton>Add new</CreateButton>
            <Button onClick={handleRefetchMerchants} variant={'outlined'}>
              Refetch Merchants
            </Button>
          </>
        ),
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={'ID'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={['BundleID']}
          title={'Bundle ID'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={['CardTypeID']}
          title={'Card Type ID'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="CloneID"
          title={'Clone ID'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={['CountryISOCode']}
          title={'Country ISO Code'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={['CountryID']}
          title={'Country ID'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={['OwnerID']}
          title={'Owner ID'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={['ReferralEquivelant']}
          title={'Referral Equivalent'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={['StationID']}
          title={'Station ID'}
          render={(value) => <TextField value={value || '-'} />}
        />

        <Table.Column
          dataIndex={['Active']}
          title={'Active'}
          align="center"
          render={(value) => <BooleanField value={value} />}
        />
        <Table.Column
          dataIndex={['ReferralActive']}
          title={'Referral Active'}
          align="center"
          render={(value) => <BooleanField value={value} />}
        />

        <Table.Column<any>
          title={'Actions'}
          dataIndex="actions"
          fixed="right"
          align="center"
          key="actions"
          render={(_text, record): any => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                <EditButton hideText size="small" recordItemId={record.id} />

                {record.is_deleted ? (
                  <TbTrashOff
                    style={{ marginLeft: 3, marginTop: 5 }}
                    size={18}
                    color={'green'}
                    className="icons"
                    onClick={() => handleRestoreRecord(record.id)}
                  />
                ) : (
                  <BsTrash
                    style={{ marginLeft: 3, marginTop: 5 }}
                    size={18}
                    color={'red'}
                    className="icons"
                    onClick={() => handleDeleteRecord(record.id)}
                  />
                )}
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
