import React, { ChangeEvent, useEffect } from 'react';
import { Edit, Form, useTable } from '@pankod/refine-antd';
import { IAccount } from '../../types';
import { useFormik } from 'formik';
import { EditRecordAction } from '../../Actions/ConfigsActions';
import countries from '../../JsonAssests/countries.json';
import { parsePhoneNumber } from 'libphonenumber-js';
import { CustomInput } from '../../Components/CustomInput';

type Inputs = {
  phone_number?: string;
  name?: string;
  email?: string;
  password?: string;
  role?: string;
  country?: string;
};
export const EditAdmin = () => {
  const { tableProps, tableQueryResult } = useTable<IAccount>({
    resource: `account-cruds`,
    initialPageSize: 100000000000000,
  });

  const record = tableProps?.dataSource?.find(
    (item) => item?.id === +window.location.href.split('edit/')[1]
  );

  const selectCountries = (countries || [])?.map((country) => {
    return { label: country?.name_en, value: country.name_en };
  });

  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const { values, setFieldValue, handleSubmit, handleChange } = useFormik({
    initialValues: {
      name: '',
      phone_number: '',
      email: '',
      country: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {};
      if (submittedValues.name !== record?.name && submittedValues.name) {
        inputs.name = submittedValues.name;
      }
      if (submittedValues.email !== record?.email) {
        inputs.email = submittedValues.email;
      }
      if (submittedValues.phone_number !== record?.phone_number) {
        inputs.phone_number = submittedValues.phone_number;
      }
      if (
        submittedValues.country !== record?.country &&
        submittedValues.country
      ) {
        inputs.country = submittedValues.country;
      }

      if (Object.keys(inputs)?.length > 0) {
        EditRecordAction(
          'account-cruds',
          record?.id,
          inputs,
          tableQueryResult?.refetch
        );
      }
    },
  });
  const buttonProps = {
    disables: false,
    loading: false,
    onClick: () => handleSubmit(),
  };
  useEffect(() => {
    if (record) {
      setFieldValue('name', record?.name);
      setFieldValue('email', record?.email);
      setFieldValue('phone_number', record?.phone_number);
      setFieldValue('country', record?.country);
    }
  }, [record, setFieldValue]);

  if (!values.country) return <></>;
  const phoneNumber = parsePhoneNumber(values.phone_number, 'JO');

  return (
    <Edit saveButtonProps={buttonProps} title={'Edit Admin'}>
      <Form layout="vertical">
        <CustomInput
          name={'name'}
          required
          onChange={handleChange}
          value={values?.name}
          label={'Name'}
          errorMessage={!values.name ? 'Name is required' : ''}
        />

        <CustomInput
          label={'Email'}
          name={'email'}
          required
          onChange={handleChange}
          value={values?.email}
          errorMessage={!regex?.test(values.email) ? 'Invalid Email' : ''}
        />

        <CustomInput
          label={'Phone'}
          name={'phone_number'}
          required
          onChange={handleChange}
          value={values?.phone_number}
          errorMessage={!phoneNumber.isValid() ? 'Invalid phone number' : ''}
        />
        <CustomInput
          label={'Country'}
          name="country"
          select
          placeholder={'Select Country'}
          required
          selectOptions={selectCountries}
          onChange={(val: string) => setFieldValue('country', val)}
          selectValue={
            values?.country as unknown as ChangeEvent<HTMLInputElement>
          }
          errorMessage={!values.country ? 'Country is required' : ''}
        />
      </Form>
    </Edit>
  );
};
