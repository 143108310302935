import React from 'react';
import {
  BooleanField,
  EditButton,
  Grid,
  ListButton,
  RefreshButton,
  Show,
  Space,
  Typography,
} from '@pankod/refine-antd';
import { useShow } from '@pankod/refine-core';
import dayjs from 'dayjs';
import { DetailsWrapper, ColumnWrapper, DescriptionWrapper } from '../../App';
import { SystemConfig } from '../../types';
// import { VscGitPullRequestCreate } from 'react-icons/vsc';
// import Swal from 'sweetalert2';
// import { MergeBranchAction } from '../../Actions/ConfigsActions';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

export const ShowSystemConfigs = () => {
  const screens = useBreakpoint();
  const { queryResult } = useShow<SystemConfig>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  // const handleMergeBranch = () => {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     showCancelButton: true,
  //     confirmButtonColor: '#db2828',
  //     confirmButtonText: 'Accept',
  //     cancelButtonText: 'Cancel',
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       record?.id && MergeBranchAction(record?.id);
  //     }
  //   });
  // };

  return (
    <Show
      isLoading={isLoading}
      pageHeaderProps={{
        extra: (
          <Space>
            {/* <Button
              onClick={handleMergeBranch}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <VscGitPullRequestCreate style={{ marginRight: 10 }} />
              {'Update Branch'}
            </Button> */}
            <ListButton />
            <EditButton />
            <RefreshButton />
          </Space>
        ),
      }}
    >
      <DetailsWrapper screenMD={!!screens.md}>
        <ColumnWrapper screenMD={!!screens.md}>
          <Title level={5}>ID</Title>
          <Text>{record?.id || '-'}</Text>
          <Title level={5}>Bundle ID</Title>
          <Text>{record?.BundleID || '-'}</Text>
          <Title level={5}>Owner ID</Title>
          <Text>{record?.OwnerID || '-'}</Text>
          <Title level={5}>Card Type ID</Title>
          <Text>{record?.CardTypeID || '-'}</Text>
          <Title level={5}>Clone ID </Title>
          <Text>{record?.CloneID || '-'}</Text>
          <Title level={5}>Station ID </Title>
          <Text>{record?.StationID || '-'}</Text>
          <Title level={5}>Expo Project ID </Title>
          <Text>{record?.build_config?.expo_project_id || '-'}</Text>
          <Title level={5}>Language ID</Title>
          <Text>{record?.LanguageID || '-'}</Text>
          <Title level={5}>Country ID</Title>
          <Text>{record?.CountryID || '-'}</Text>
          <Title level={5}>Country ISO Code</Title>
          <Text>{record?.CountryISOCode || '-'}</Text>
          <Title level={5}>Firebase Api Key</Title>
          <DescriptionWrapper screenMD={!!screens.md}>
            <Text>{record?.build_config?.firebase_api_key || '-'}</Text>
          </DescriptionWrapper>
          <Title level={5}>Firebase App ID </Title>
          <DescriptionWrapper screenMD={!!screens.md}>
            <Text>{record?.build_config?.firebase_app_id || '-'}</Text>
          </DescriptionWrapper>

          <Title level={5}>Firebase Auth Domain </Title>
          <DescriptionWrapper screenMD={!!screens.md}>
            <Text>{record?.build_config?.firebase_auth_domain || '-'}</Text>
          </DescriptionWrapper>
          <Title level={5}>Firebase Message Sender ID </Title>
          <Text>{record?.build_config?.firebase_message_sender_id || '-'}</Text>
          <Title level={5}>Firebase Project ID </Title>
          <Text>{record?.build_config?.firebase_project_id || '-'}</Text>
        </ColumnWrapper>

        <ColumnWrapper screenMD={!!screens.md}>
          <Title level={5}>App Name </Title>
          <Text>{record?.build_config?.app_name || '-'}</Text>
          <Title level={5}>App Logo </Title>
          <DescriptionWrapper screenMD={!!screens.md}>
            <a href={record?.build_config?.app_logo || ''} target="_blank ">
              <Text style={{ color: 'blue' }}>
                {record?.build_config?.app_logo || '-'}
              </Text>
            </a>
          </DescriptionWrapper>

          <Title level={5}>Referral Currency </Title>
          <Text>{record?.ReferralCurrency || '-'}</Text>
          <Title level={5}>Referral Equivalent </Title>
          <Text>{record?.ReferralEquivelant || '-'}</Text>
        </ColumnWrapper>

        <ColumnWrapper screenMD={!!screens.md}>
          <Title level={5}>Android Client ID </Title>
          <DescriptionWrapper screenMD={!!screens.md}>
            <Text>{record?.build_config?.android_client_id || '-'}</Text>
          </DescriptionWrapper>
          <Title level={5}>Expo Client ID </Title>
          <DescriptionWrapper screenMD={!!screens.md}>
            <Text>{record?.build_config?.expo_client_id || '-'}</Text>
          </DescriptionWrapper>
          <Title level={5}>App Identifier </Title>
          <Text>{record?.build_config?.app_identifier || '-'}</Text>
          <Title level={5}>Ios Client ID </Title>
          <DescriptionWrapper screenMD={!!screens.md}>
            <Text>{record?.build_config?.ios_client_id || '-'}</Text>
          </DescriptionWrapper>
          <Title level={5}>Web Client ID </Title>
          <DescriptionWrapper screenMD={!!screens.md}>
            <Text>{record?.build_config?.web_client_id || '-'}</Text>
          </DescriptionWrapper>
          <Title level={5}>Production Payment Merchant ID </Title>
          <Text>
            {record?.build_config?.production_payment_merchant_id || '-'}
          </Text>
          <Title level={5}>App Description </Title>
          <Text>{record?.build_config?.app_description || '-'}</Text>
          <Title level={5}>Payment Provider </Title>
          <Text>{record?.build_config?.payment_provider || '-'}</Text>
          <Title level={5}>Slug Name </Title>
          <Text>{record?.build_config?.slug_name || '-'}</Text>
          <Title level={5}>Tid11 </Title>
          <Text>{record?.build_config?.tid11 || '-'}</Text>
          <Title level={5}>Privacy And Policy Url </Title>
          <DescriptionWrapper screenMD={!!screens.md}>
            <Text>{record?.build_config?.privacy_and_policy_url || '-'}</Text>
          </DescriptionWrapper>
          <Title level={5}>Terms And Conditions Url </Title>
          <DescriptionWrapper screenMD={!!screens.md}>
            <Text>{record?.build_config?.terms_and_conditions_url || '-'}</Text>
          </DescriptionWrapper>

          <Title level={5}>Active </Title>
          <BooleanField value={record?.Active} />

          <Title level={5}>Referral Active </Title>
          <BooleanField value={record?.ReferralActive} />

          <Title level={5}>Created At</Title>
          <Text>
            {record?.created_at
              ? dayjs(record?.created_at).format('MMMM DD, YYYY HH:MM')
              : '-'}
          </Text>
        </ColumnWrapper>
      </DetailsWrapper>
    </Show>
  );
};
